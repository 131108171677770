/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Pagina from '../components/Pagina'
import './black-leste.scss'
import CounterFlip from '../components/black-friday-counter/black-friday-counter'
import Swiper from 'react-id-swiper'
import axios from 'axios'
import { GatsbyImage } from 'gatsby-plugin-image'
import CountDown from '../components/counter/counter'
import { Link as ScrollerLink } from 'react-scroll'
import BuscarLoja from '../components/SearchStoreBlackLeste/searchStoreBlackLeste'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import swiper from 'swiper'
import { Modal } from 'reactstrap'

import arrow from '../assets/icons/black-leste/arrow.svg'
import desconto from '../assets/icons/black-leste/desconto.svg'
import wpp from '../assets/icons/black-leste/whatsapp.svg'
import decoration from '../assets/icons/black-leste/decoration.svg'
import logoblackleste from '../assets/icons/black-leste/logo-blackleste.svg'
import add from '../assets/icons/black-leste/add.svg'

const swiperParams = {
  spaceBetween: 30,
  slidesPerView: 2,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  breakpoints: {
    1024: {
      slidesPerView: 3,
      spaceBetween: 0
    }
  }
}

const BlackLeste = (register, setValue, field) => {
  const data = useStaticQuery(
    graphql`
    query {
      pagina:contentfulPagina(path: { eq: "/black-leste/" }) {
        path
        metaTitle
        metaDescription
        tags
      }
      allCloudOfertasTabloide(filter: {tariff: {name: {eq: "BLACK-LESTE"}}}) {
        edges {
          node {
            offer {
              id
              timeToShow
              finishDate
              highlight
              offerGroup {
                name
                timeToShow
              }
              category {
                name
              }
              image {
                url
                childImageSharp {
                  gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                }
              }
              brand {
                image {
                  url
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
      allCloudOfertasCategoria {
        edges {
          node {
            name
          }
        }
      }
      lojas: allCloudOfertasLoja {
        nodes {
          id
          slug
          address
          cep
          city
          district
          lat
          lng
          name
          reference
          status
          uf
          whatsapp
          services {
            icon
            name
          }
          fridayClose
          fridayIsOpen
        }
      }
    }
  `
  )

  const categoriesOffers = []
  let offers
  data.allCloudOfertasTabloide.edges.forEach((value) => {
    offers = value.node.offer
  })

  const [allOffers, setAllOffers] = useState(offers !== undefined && offers.filter((item) => item.offerGroup === null))
  console.log('alow', allOffers)
  const groupOffers = offers !== undefined && offers.filter((item) => item.offerGroup !== null && item.offerGroup.name === '48h arrasadoras')

  data.allCloudOfertasCategoria.edges.forEach((item) => {
    const offersFiltered = offers !== undefined && offers.filter((offer) => offer.category.name === item.node.name).filter((item) => item.timeToShow !== null && item.offerGroup === null)
    categoriesOffers.push({
      name: item.node.name,
      offers: offersFiltered
    })
  })

  // console.log('aa', categoriesOffers)
  const favoriteStatus = async () => {
    const userId = window.localStorage.getItem('userId')

    const config = {
      headers: {
        accessToken: process.env.GATSBY_CLOUDOFERTAS_SITE_KEY
      }
    }

    const getFavoriteOffer = await axios.get(`${process.env.GATSBY_CLOUDOFERTAS_API_BOOKLET}/organizations/1/sites/42100970-3f88-4515-b060-8c15c7bb3133/users/${userId}`, config)
    console.log('ofertas favoritas', getFavoriteOffer)

    // eslint-disable-next-line array-callback-return
    const updatedOffers = allOffers.map((offer) => {
      const favoriteMatch = getFavoriteOffer.data.find((data) => {
        return data.offer === offer.id
      })
      if (favoriteMatch) {
        offer.favorito = true
      } else {
        offer.favorito = false
      }
      return offer
    })

    setAllOffers(updatedOffers)
  }

  useEffect(async () => {
    if (typeof window !== 'undefined') {
      const current = window.localStorage.getItem('userId')
      if (!current) {
        window.localStorage.setItem('userId', uuidv4())
      }
    }
    favoriteStatus()
  }, [])

  const uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }

  const eventHandler = async (offer) => {
    const notify = () => toast.success('Oferta adicionada ao carrinho', {
      theme: 'colored',
      hideProgressBar: true,
      icon: <img src={add} />
    })
    const userId = window.localStorage.getItem('userId')
    const payload = {
      image: offer.image.url
    }
    const offerCopy = JSON.parse(JSON.stringify(offer))
    const config = {
      headers: {
        accessToken: process.env.GATSBY_CLOUDOFERTAS_SITE_KEY

      }
    }
    let value
    try {
      if (offer.favorito === true) {
        value = false
        await axios.delete(`${process.env.GATSBY_CLOUDOFERTAS_API_BOOKLET}/organizations/1/sites/42100970-3f88-4515-b060-8c15c7bb3133/users/${userId}/offers/${offer.id}`, config)
        console.log('delete')
      } else {
        value = true
        await axios.post(`${process.env.GATSBY_CLOUDOFERTAS_API_BOOKLET}/organizations/1/sites/42100970-3f88-4515-b060-8c15c7bb3133/users/${userId}/offers/${offer.id}`, payload, config)
        console.log('post')
        notify()
      }
    } catch (err) {
      console.log('ERRO: ', err)
      value = offerCopy.favorito
    }

    const updatedAllOffers = allOffers.map(item => {
      if (item.id === offer.id) {
        item.favorito = value
      }

      return item
    })

    setAllOffers(updatedAllOffers)
  }

  const verifyStringName = (data, string) => {
    return Number(data) === 1 ? string : string + 'S'
  }

  const MainCount = ({ time }) => {
    return (
      <div className='counter'>
        <div>
          <CounterFlip value={time.d}></CounterFlip>
          <div className='text'>{verifyStringName(time.d, 'DIA')}</div>
        </div>
        <div className='mx-3'>
          <CounterFlip value={time.h}></CounterFlip>
          <div className='text'>{verifyStringName(time.h, 'HORA')}</div>
        </div>
        <div className='mr-3'>
          <CounterFlip value={time.m}></CounterFlip>
          <div className='text'>{verifyStringName(time.m, 'MINUTO')}</div>
        </div>
        <div>
          <CounterFlip value={time.s}></CounterFlip>
          <div className='text'>{verifyStringName(time.s, 'SEGUNDO')}</div>
        </div>
      </div>
    )
  }

  const GroupCount = ({ time, remTime }) => {
    return (
      <div className='container'>
        <div className=' text-center mt-5 borderBox'>
          <div className='boxOfertas'>
            <div className='counterDiv'>
              <h3 className='fontGroup'>Prepare-se para 48h arrasadoras</h3>
              <div className='counterNumbers'>
                <div className='legenda'>
                  <p>{time.d}:</p>
                  <div className='text'>{verifyStringName(time.d, 'DIA')}</div>
                </div>
                <div className='legenda'>
                  <p>{time.h}:</p>
                  <div className='text'>{verifyStringName(time.h, 'HORA')}</div>
                </div>
                <div className='legenda'>
                  <p>{time.m}:</p>
                  <div className='text'>{verifyStringName(time.m, 'MINUTO')}</div>
                </div>
                <div className='legenda'>
                  <p>{time.s}</p>
                  <div className='text'>{verifyStringName(time.s, 'SEGUNDO')}</div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='text center'>
            <h3 className='fontGroup'>Prepare-se para 48h arrasadoras</h3>
          </div> */}
          <div className='ofertasCard ofertaSwiper row pt-0'>
            <Swiper {...swiperParams}>
              { offers !== undefined &&
                groupOffers.map((item, index) => {
                  return (
                    <div className='card d-flex justify-content-center' key={`groupOffers-${index}`}>
                      {/* <button className={`star ${item.favorito === true ? 'starActive' : ''}`} onClick={() => eventHandler(item)}></button> */}
                      <div>
                        {remTime < 0
                          ? <GatsbyImage image={item.image.childImageSharp.gatsbyImageData} className='m-2' alt='imagem das ofertas black friday' />
                          : <GatsbyImage image={item.brand.image.childImageSharp.gatsbyImageData} className='m-2' alt='imagem da marca do produto. Oferta ainda não revelada' />
                        }
                      </div>
                    </div>
                  )
                })
              }
            </Swiper>
          </div>
        </div>
      </div>

    )
  }

  const ValidityCount = ({ time }) => {
    return (
      <>
        <div className='d-flex justify-content-center align-items-center'>
          <hr></hr>
        </div>
        <div className='d-flex justify-content-center'>
          <div>
            <h4>{time.h}</h4>
            <p className='countdown mb-1'>{verifyStringName(time.h, 'hora')}</p>
          </div>
          <h4 className='px-0'>:</h4>
          <div>
            <h4>{time.m}</h4>
            <p className='countdown mb-1'>{verifyStringName(time.m, 'minuto')}</p>
          </div>
          <h4 className='px-0'>:</h4>
          <div>
            <h4>{time.s}</h4>
            <p className='countdown mb-1'>{verifyStringName(time.s, 'segundo')}</p>
          </div>
        </div>
      </>
    )
  }

  const IndividualCount = ({ time, item, remTime }) => {
    return (
      <>
        {/* <div className='d-flex justify-content-end p-2'>
          <button className={`star ${item.favorito === true ? 'starActive' : ''}`} onClick={() => eventHandler(item)}></button>
        </div> */}
        <div>
          {remTime < 0
            ? <>
              <div className='pt-2'><GatsbyImage image={item.image.childImageSharp.gatsbyImageData} className='m-2' alt='imagem das ofertas black friday' /></div>
              {item.finishDate &&
                <div className='d-flex justify-content-center'>
                  <CountDown Component={ValidityCount} date={item.finishDate} item={item} />
                </div>
              }

              {item.favorito === false
                ? <button className='mx-2 mb-2 add' onClick={() => eventHandler(item)}>Adicionar ao Carrinho</button>
                : <button className='mx-2 mb-2 added' onClick={() => eventHandler(item)}>Adicionado</button>
              }
            </>
            : <GatsbyImage image={item.brand.image.childImageSharp.gatsbyImageData} className='m-2' alt='imagem da marca do produto. Oferta ainda não revelada' />
          }
        </div>
        {remTime > 0 &&
          <>
            <div className='d-flex justify-content-center align-items-center'>
              <hr></hr>
            </div>
            <div className='d-flex justify-content-center'>
              <div>
                <h4>{time.h}</h4>
                <p className='countdown'>{verifyStringName(time.h, 'hora')}</p>
              </div>
              <h4 className='px-0'>:</h4>
              <div>
                <h4>{time.m}</h4>
                <p className='countdown'>{verifyStringName(time.m, 'minuto')}</p>
              </div>
              <h4 className='px-0'>:</h4>
              <div>
                <h4>{time.s}</h4>
                <p className='countdown'>{verifyStringName(time.s, 'segundo')}</p>
              </div>
            </div>
          </>
        }
      </>
    )
  }
  const [lojas, setlojas] = useState(data.lojas.nodes)

  const buscaEndereco = (location) => {
    event.preventDefault()

    let cep = location.match(/^([\d]{8}|[\d]{5}-?[\d]{3})$/g)
    cep = cep.toString().replace('-', '')

    const { geocoder } = this.props
    geocoder.geocode({
      address: location,
      region: 'br',
      componentRestrictions: {
        country: 'BR'
      }
    },
      (results, status) => {
        console.log('google results', { results: results, status: status })

        if (status === 'OK') {
          const pos = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          }
          this.criaMarcador(pos)
          this.calculaDistancia(pos)
          this.selecionaLoja(lojas[0].id)
        } else {
          console.log('FAIL')
        }
      }
    )
  }

  const [text, setText] = useState(false)

  const currentStore = typeof window !== 'undefined' ? window.localStorage.getItem('currentMarket') : ''
  const currentMarketJson = currentStore ? JSON.parse(currentStore) : ''
  const [currentMarket, setCurrentMarket] = useState(currentMarketJson)
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const currentDate = new Date()

  return (
    <Fragment>
      <Pagina pagina={data.pagina} />
      <ToastContainer hideProgressBar theme="colored" />
      <main id="blackleste">
        <div className='background-blue'>
          <div className='d-flex justify-content-center pt-5'>
            <img src={logoblackleste} width='510px' className='position-absolute logoBlackLeste d-md-block d-none' alt='Logo Drogaleste' />
            <img src={logoblackleste} width='320px' className='position-absolute logoBlackLeste d-md-none d-block' alt='Logo Drogaleste' />
          </div>
        </div>
        <div className='marginLogo'></div>
        <div className='container text-center'>
          <h3 className=''>OS QUERIDINHOS DO ANO COM ATÉ 70% OFF!</h3>
        </div>
        <div className=''>
          <div className='d-md-block d-md-none'>
            <p className='text-center text-white bold'>Acaba em: </p>
          </div>
          <CountDown Component={MainCount} date={'Nov 27, 2022 00:00:00'} />
        </div>
        <div className='container text-center'>
          <h3 className='pt-5'>COMO FUNCIONA?</h3>
          {text === false &&
            <div onClick={() => setText(!text)} className='cursor-pointer'>
              <img src={arrow} width='5%' className='py-3' />
              <p><u>Clique aqui para exibir as informações</u></p>
            </div>
          }
          {text === true &&
            <>
              <div className='cursor-pointer' onClick={() => setText(!text)}>
                <img src={arrow} width='5%' className='py-3 rotateArrow' />
                <p role='button' ><u>Ocultar informações</u></p>
              </div>

              <p className='font-md bold'>Você sabe como aproveitar as ofertas da Black Leste 2022? É muito rápido e simples, veja como se faz</p>
              <ol>
                <li>Primeiro, escolha as ofertas de sua preferência e clique no botão “adicionar ao carrinho”.</li>
                <li>Ao finalizar a seleção de todos os produtos, clique no botão com o ícone do WhatsApp “finalizar carrinho”. Em seguida escolha em qual Droga Leste irá retirar as suas compras, no campo “digite seu CEP ou Bairro” ou “Usar minha localização atual”.</li>
                <li>Você será direcionado para a tela “Meu Carrinho” e poderá confirmar todas as ofertas selecionadas anteriormente, para finalizar clique em “Enviar pedido para loja mais próxima”
                  A tela do seu WhatsApp será aberta e direcionada para a Droga Leste escolhida, pronto! É só apertar o botão enviar e em seguida um atendente dará andamento ao seu pedido.

                  Boas compras!</li>
              </ol>
            </>
          }
        </div>
        <Modal isOpen={modal} onHide={() => setModal(false)} size='lg' centered={true} >
          <div id='searchStore'>
            <BuscarLoja current={currentMarket} setCurrentMarket={setCurrentMarket} toggle={toggle} />
          </div>
        </Modal>
        <section className='container text-center'>
          <h4 className='pt-5'>Compre pelo WhatsApp</h4>
        </section>
        <CountDown Component={GroupCount} date={offers !== undefined && groupOffers.length !== 0 ? groupOffers[0].offerGroup.timeToShow : ''} />
        <div className='container pt-5 text-center'>
          <h3 className='pb-3'>Confira todas as nossas ofertas da Black</h3>
          <h4 className='fontCarrinho'>Clique no botão ’Adicionar ao Carrinho’ para comprar as ofertas</h4>
          <div className='row ofertas pb-0'>
            {offers !== undefined &&
              allOffers.map(item => {
                const timeToHide = new Date(item.finishDate)
                return (
                  <div>
                    {timeToHide > currentDate &&
                      <div className={`card justify-content-end ${item.highlight === true ? 'cardEspecial' : ''}`} key={`allOffers-${item.id}`}>
                        <CountDown Component={IndividualCount} date={item.timeToShow} item={item} />
                      </div>
                    }
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className='whatsButton container pb-3' onClick={() => setModal(!modal)}>
          <button className='p-2'><img src={wpp} />
            FINALIZAR CARRINHO
          </button>
        </div>
      </main>
    </Fragment>
  )
}
export default BlackLeste
