/* eslint-disable space-before-function-paren */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useRef } from 'react'
import { Link, navigate, graphql, useStaticQuery } from 'gatsby'
import lupa from '../../assets/icons/all/lupa.svg'
import target from '../../assets/icons/all/target.svg'
import pin from '../../assets/icons/all/pin.svg'
import close from '../../assets/icons/all/icon-close.svg'
import { Link as ScrollerLink } from 'react-scroll'
import wpp from '../../assets/icons/black-leste/whatsapp.svg'

import { globalHistory } from '@reach/router'
import './searchStoreBlackLeste.scss'
import { type } from 'jquery'

const OfertasSearch = ({ lojas, current, setCurrentMarket, toggle }) => {
  console.log(lojas, current)
  const [markets, setMarkets] = useState(lojas.nodes.map(node => (node)))
  const [searchLabel, setSearchLabel] = useState(false)
  const [selectedStore, setSelectedStore] = useState(false)
  const [displaySearchContainer, setDisplaySearchContainer] = useState(false)
  const [currentStoreName, setCurrentStore] = useState(null)

  useEffect(() => {
    const current = JSON.parse(window.localStorage.getItem('currentStore'))
    if (current && current.name) {
      setCurrentStore(current.name)
    }
    console.log(current)
  }, [])

  const [itemsToShow, _setItemsToShow] = useState(10)
  const itemsToShowRef = React.useRef(itemsToShow)
  const setItemsToShow = data => {
    itemsToShowRef.current = data
    _setItemsToShow(data)
  }

  const path = globalHistory.location.pathname

  function onSuccess(position) {
    const pos = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    }
    console.log('success')
    calcDistance(pos)
  }

  function onError(error) {
    let txt
    switch (error.code) {
      case error.PERMISSION_DENIED:
        txt = 'Location permission denied'
        break
      case error.POSITION_UNAVAILABLE:
        txt = 'Location position unavailable'
        break
      case error.TIMEOUT:
        txt = 'Location position lookup timed out'
        break
      default:
        txt = 'Unknown position.'
    }
    console.error(txt)
  }

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?language=pt-BR&libraries=places&key=${process.env.GATSBY_CREDENTIAL_API_MAPS_LOJAS}`
      const insercao = document.getElementsByTagName('script')[0]
      insercao.parentNode.insertBefore(script, insercao)
      script.addEventListener('load', e => {
        console.log('Geocoder is now available!')
      })
    }

    if (document.querySelector('#search-container-home')) {
      document.querySelector('#search-container-home').addEventListener('scroll', handleInfinityScroll)
    }
    enableScroll()
  })

  const enableScroll = () => {
    document.getElementsByTagName('html')[0].style.overflow = 'auto'
    document.getElementsByTagName('html')[0].style.position = 'relative'
    document.getElementsByTagName('html')[0].style.width = '100%'
  }

  const handleInfinityScroll = (event) => {
    const { scrollTop, offsetHeight, scrollHeight } = event.target
    const delta = Math.round(scrollTop + offsetHeight)
    if (delta >= scrollHeight - 5) {
      setItemsToShow(itemsToShowRef.current + 10)
    }
  }

  const useCurrentLocation = () => {
    setDisplaySearchContainer(true)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError)
    } else {
      console.error('Geolocation is not supported by this browser.')
    }
  }

  const calcDistance = (p) => {
    console.log('>> CalcDistance')
    const myLat = p.lat
    const myLng = p.lng
    let mkts = lojas.nodes.map(node => {
      const LatLoja = node.lat
      const LngLoja = node.lng
      const radlat1 = (Math.PI * myLat) / 180
      const radlat2 = (Math.PI * LatLoja) / 180

      const theta = myLng - LngLoja
      const radtheta = (Math.PI * theta) / 180
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
      dist = Math.acos(dist)
      dist = (dist * 180) / Math.PI
      dist = dist * 60 * 1.1515
      dist = dist * 1.609344
      node.distance = parseFloat(dist.toFixed(1))
      return node
    })
    mkts = mkts.sort((a, b) => (a.distance - b.distance))
    console.log(mkts)
    setMarkets(mkts)
  }

  const resetInfinityScroll = () => {
    setItemsToShow(10)
    document.querySelector('#search-container-home').scrollTop = 0
  }

  const handleSearch = (e) => {
    setDisplaySearchContainer(true)
    e.preventDefault()
    document.getElementById('resultadoLojas').scrollIntoView({ behavior: 'smooth', block: 'center' })

    const geocoder = new window.google.maps.Geocoder()

    geocoder.geocode({
      address: endereco,
      region: 'br',
      componentRestrictions: {
        country: 'BR'
      }
    },
      (results, status) => {
        console.log('google results', { results: results, status: status })

        if (status === 'OK') {
          const pos = {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          }

          setSearchLabel(results[0].formatted_address)
          calcDistance(pos)
          resetInfinityScroll()
        } else {
          console.log('FAIL')
        }
      })
  }

  const handleMarketSelected = (market) => {
    const validarHeaderStore = typeof window !== 'undefined' && window.localStorage.getItem('currentStore')
    if (!validarHeaderStore) {
      document.getElementById('spanStore').classList.toggle('d-xl-inline')
    }

    setCurrentMarket(market)
    setDisplaySearchContainer(false)
    setSearchLabel(false)
    setSelectedStore(true)
    setCurrentStore(market.name)

    if (typeof window !== 'undefined') {
      window.localStorage.setItem('currentStore', JSON.stringify(market))
      document.getElementById('currentMarket').innerHTML = `LOJA ${market.storeNumber}`
      document.getElementById('currentMarket').setAttribute('href', `/lojas/${market.slug}/contato/`)

      if (path.match(/\/lojas\/[a-z]/)) {
        navigate(`/lojas/${market.slug}`)
      }
      const currentStore = typeof window !== 'undefined' ? window.localStorage.getItem('currentStore') : ''
      const currentMarket = currentStore ? JSON.parse(currentStore) : ''
    }
  }
  console.log(currentStoreName)

  const [endereco, setEndereco] = useState('')

  return (
    <div className='container mt-4'>
      <div className='container buscarLoja text-center'>
          <p className='bold m-0 mb-3'>Selecione a loja de sua preferência:</p>
          <button onClick={() => toggle(true)} className='buttonClose'><img src={close} width='15px'></img></button>
        <div className="row justify-content-center ">
          <form className='searchStore col-12 '>
            <label htmlFor="input-search"></label>
            <input type="text" name="input-search" id="input-search" className="w-100 text-dark" placeholder="Digite seu CEP, Cidade ou Bairro" onChange={(event) => setEndereco(event.target.value)} />
            <button onClick={(event) => handleSearch(event)} type="submit" name="button" className="position-absolute" aria-label="search-button">
              <img src={lupa} className="imgLupa position-absolute" alt="search icon" /></button>
          </form>
          <div className='align-items-center d-flex pl-2 pr-2'>
            <span className='font-size-13'>ou</span>
          </div>
          <div className="botaoLocation col-12 ">
            <button className="w-100" onClick={() => useCurrentLocation()}><span className='pr-2 font-size-16'>Usar minha localização atual</span>
              <img src={target} width='20px'></img>
            </button>
          </div>
        </div>
        <div className="col-12 p-0">
          {currentStoreName !== null &&
            <>
              <div className='pt-3 pb-3 d-flex justify-content-center align-content-center'>
                  <img src={pin} width='19px'></img>
                  <span className='pl-1'>Loja Selecionada: </span><span className='bold pl-1'>{current.name !== undefined ? current.name : currentStoreName}</span>
              </div>
              <div className='whatsButton pb-3'>
                <Link to='/favoritos/'><button className='btn btn-block w-100'><img src={wpp} />ENVIAR A LISTA DE CARRINHO PARA A LOJA</button></Link>
              </div>
            </>
          }
          {currentStoreName === null &&
            <div className='pt-3'>
              <p>
                <span className='pl-1'>Nenhuma loja selecionada</span>
              </p>
            </div>
          }
          {
            searchLabel && (
              <div className="">
                <h4 className="text-center size18 mt-0 resultadoBusca font-size-16">Resultados de Busca para "{searchLabel}"</h4>
              </div>
            )
          }

        </div>
      </div>
      <div className='container resultadoLojas' id='resultadoLojas'>
        {displaySearchContainer &&
          <div className="row ">
            <div className="col-12 p-0">
              <div id="search-container-home">
                {markets.slice(0, itemsToShow).map(market => {
                  return (
                    <div className="cardsLojas" key={`A${market.storeNumber}`}>
                      <div className="row align-items-center m-0 p-2">
                        <div className="col-lg-9 col-12 text-dark">
                          <p className="font-weight-bold text-light-blue">{market.name}</p>
                          <p>{market.address} <br />
                            {market.district} - {market.city} - {market.uf}
                          </p>
                        </div>
                        <div className='col-lg-3 col-auto'>
                          <button type="button" className="btn w-100" onClick={() => handleMarketSelected(market)}>
                            Selecionar Loja
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

const BuscarLoja = ({ current, setCurrentMarket, toggle }) => {
  const query = useStaticQuery(
    graphql`{
      lojas: allCloudOfertasLoja {
        nodes {
          id
          slug
          storeNumber
          address
          cep
          city
          district
          lat
          lng
          name
          reference
          status
          uf
          whatsapp
          mondayOpen
          mondayClose
          holidayOpen
          holidayClose
          sundayOpen
          sundayClose
          services {
            icon
            name
          }
          tariff {
            id
            name
          }
        }
      }
    }
`
  )

  return (
    <>
      <OfertasSearch current={current} lojas={query.lojas} setCurrentMarket={setCurrentMarket} toggle={toggle} />
    </>
  )
}

export default BuscarLoja
